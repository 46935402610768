exports.components = {
  "component---128798040-uery-tsx-content-file-path-content-projects-beauty-index-mdx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/beauty/index.mdx" /* webpackChunkName: "component---128798040-uery-tsx-content-file-path-content-projects-beauty-index-mdx" */),
  "component---1444978754-rts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---1444978754-rts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---1772261572-ge-query-tsx-content-file-path-content-pages-about-index-mdx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/vercel/path0/content/pages/about/index.mdx" /* webpackChunkName: "component---1772261572-ge-query-tsx-content-file-path-content-pages-about-index-mdx" */),
  "component---225775144-ery-tsx-content-file-path-content-projects-fashion-index-mdx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/fashion/index.mdx" /* webpackChunkName: "component---225775144-ery-tsx-content-file-path-content-projects-fashion-index-mdx" */),
  "component---3075896663-sx-content-file-path-content-projects-01-polaroids-index-mdx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/01-polaroids/index.mdx" /* webpackChunkName: "component---3075896663-sx-content-file-path-content-projects-01-polaroids-index-mdx" */),
  "component---3281615897--query-tsx-content-file-path-content-pages-contact-index-mdx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/vercel/path0/content/pages/contact/index.mdx" /* webpackChunkName: "component---3281615897--query-tsx-content-file-path-content-pages-contact-index-mdx" */),
  "component---52722206-rts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---52722206-rts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---564896835-ry-tsx-content-file-path-content-projects-campaign-index-mdx": () => import("./../../../node_modules/.pnpm/@lekoarts+gatsby-theme-jodie-core@5.1.7_gatsby@5.13.6_@types+webpack@5.28.5_babel-eslint@10.1_bkgooat2uv2onzgv2npma4vww4/node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/vercel/path0/content/projects/campaign/index.mdx" /* webpackChunkName: "component---564896835-ry-tsx-content-file-path-content-projects-campaign-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

